import { baseUrl } from '@percent/partner-dashboard/services/apiPaths'
import { PBPListResponse } from '@percent/partner-dashboard/services/types/pbpResponses.types'

import { Fundraiser, FundraisersServiceCapabilities, GetFundraisersListProps } from './fundraisersService.types'

export const fundraisersService = ({ percentClient }: FundraisersServiceCapabilities) => ({
  getFundraisersList: (params: GetFundraisersListProps) => {
    const { status, query, ...otherParams } = params

    return percentClient.get<PBPListResponse<Fundraiser>>(`${baseUrl}partner/fundraisers`, {
      params: {
        ...otherParams,
        ...(status && { status }),
        ...(query && { query })
      }
    })
  }
})
