import { useDidMountEffect, useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { StringParam, useQueryParams } from 'use-query-params'
import { FundraisersTable } from './FundraisersTable/FundraisersTable'
import {
  FundraiserStatus,
  GetFundraisersListProps
} from '@percent/partner-dashboard/services/fundraisers/fundraisersService.types'

function mapStatusToEnum(status: string | null | undefined): FundraiserStatus | undefined {
  switch (status) {
    case FundraiserStatus.Published:
    case FundraiserStatus.Ended:
    case FundraiserStatus.Closed:
      return status
    default:
      return undefined
  }
}

export function FundraisersContainer() {
  const { fundraisersService } = useServices()

  const [queryParams, setQueryParams] = useQueryParams(
    {
      status: StringParam,
      query: StringParam
    },
    {}
  )

  const listParams: GetFundraisersListProps = {
    ...queryParams,
    status: mapStatusToEnum(queryParams.status)
  }

  const [{ data, isLoading, errorMessage, totalResults }, { query, nextPage, previousPage, refresh }] = useQueryList(
    fundraisersService.getFundraisersList,
    listParams
  )

  useDidMountEffect(() => query(listParams), [queryParams, query])

  return (
    <FundraisersTable
      data={data}
      isLoading={isLoading}
      totalResults={totalResults}
      errorMessage={errorMessage}
      nextPage={nextPage}
      previousPage={previousPage}
      query={query}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      refresh={refresh}
    />
  )
}
